import { prop, path } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { ASYNC_STATUSES } from '@neo/constants'

const selectInvitation = prop('invitation')

export const selectGetInvitationStatus = path([
  'asyncState',
  'invitation/getInvitation',
  'status',
])

export const selectInvitationCode = createSelector(
  selectInvitation,
  prop('invitationCode')
)

export const selectAcceptInvitationStatus = path([
  'asyncState',
  'invitation/acceptInvitation',
  'status',
])

export const selectHasAcceptInvitationSucceeded = createSelector(
  selectAcceptInvitationStatus,
  (status) => status === ASYNC_STATUSES.FULFILLED
)

export const selectEmail = createSelector(selectInvitation, prop('email'))
export const selectRole = createSelector(selectInvitation, prop('role'))
