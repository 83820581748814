import { useEffect, useCallback } from 'react'
import { AuthSdk as Wallet } from '@midas/idkit'
import { AUTH } from '@neo/constants'
import { isNilOrEmpty, path } from '@solta/ramda-extra'

// this needs to be imported for window.xprops to be defined
// eslint-disable-next-line unused-imports/no-unused-imports, no-unused-vars
import { AuthComponent } from '@vega/services'
import Cookies from 'js-cookie'

import { isDev } from 'utils/isDev'

const { AUTH_COOKIE_KEY, AUTH_ID_TOKEN_KEY, CREDENTIALS_COOKIE_KEY } = AUTH

const VEGA_SUBDOMAIN = process.env.REACT_APP_VEGA_SUBDOMAIN

function RPC() {
  const onCredentialsUpdate = useCallback((data) => {
    if (isNilOrEmpty(data)) {
      Cookies.set(CREDENTIALS_COOKIE_KEY, null, {
        domain: VEGA_SUBDOMAIN,
        secure: !isDev(),
        sameSite: 'strict',
      })

      if (window?.xprops?.onAuthSessionRefreshed) {
        window.xprops.onAuthSessionRefreshed()
      }
      return
    }

    Cookies.set(CREDENTIALS_COOKIE_KEY, JSON.stringify(data), {
      domain: VEGA_SUBDOMAIN,
      secure: !isDev(),
      sameSite: 'strict',
    })
  }, [])

  const onAuthSessionUpdate = useCallback(
    (data) => {
      if (isNilOrEmpty(data)) {
        Cookies.set(AUTH_COOKIE_KEY, null, {
          domain: VEGA_SUBDOMAIN,
          secure: !isDev(),
          sameSite: 'strict',
        })

        Cookies.set(AUTH_ID_TOKEN_KEY, null, {
          domain: VEGA_SUBDOMAIN,
          secure: !isDev(),
          sameSite: 'strict',
        })

        if (window?.xprops?.onAuthSessionRefreshed) {
          window.xprops.onAuthSessionRefreshed()
        }

        return
      }

      const authSession = {
        accessToken: path(['accessToken', 'jwtToken'], data),
        refreshToken: path(['refreshToken', 'token'], data),
        expiryDate: path(['accessToken', 'payload', 'exp'], data),
      }

      Cookies.set(AUTH_COOKIE_KEY, JSON.stringify(authSession), {
        domain: VEGA_SUBDOMAIN,
        secure: !isDev(),
        sameSite: 'strict',
      })

      const idToken = { idToken: path(['idToken', 'jwtToken'], data) }

      Cookies.set(AUTH_ID_TOKEN_KEY, JSON.stringify(idToken), {
        domain: VEGA_SUBDOMAIN,
        secure: !isDev(),
        sameSite: 'strict',
      })

      Wallet.getCurrentCredentials().then(onCredentialsUpdate)

      if (window?.xprops?.onAuthSessionRefreshed) {
        window.xprops.onAuthSessionRefreshed()
      }
    },
    [onCredentialsUpdate]
  )

  useEffect(() => {
    Wallet.getCurrentSession().then(onAuthSessionUpdate)

    Wallet.on('tokenChange', onAuthSessionUpdate)
  }, [onAuthSessionUpdate, onCredentialsUpdate])

  return null
}

export { RPC }
