/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ASYNC_STATUSES, USER_ROLES } from '@neo/constants'
import { Loading, ErrorPage } from '@vega/components'

import {
  CreateAccount as CreateLendingAccount,
  CreateClientAccount as CreateLendingClientAccount,
} from './lending'
import { CreateClientAccount as CreateBankingClientAccount } from './banking'
import {
  getInvitation,
  selectRole,
  selectGetInvitationStatus,
} from 'modules/invitation'

const {
  CLIENT,
  CREDIT_ASSESSOR,
  BROKER,
  BANKING_PORTFOLIO_ADMIN,
  BANKING_ADMIN,
  BANKING_CLIENT,
  LEAD_ASSESSOR,
} = USER_ROLES

const CreateAccount = () => {
  const { PENDING, REJECTED } = ASYNC_STATUSES

  const dispatch = useDispatch()

  const role = useSelector(selectRole)
  const status = useSelector(selectGetInvitationStatus)

  const getCreateAccountPage = (role = CLIENT) =>
    ({
      [CLIENT]: <CreateLendingClientAccount />,
      [BROKER]: <CreateLendingAccount />,
      [CREDIT_ASSESSOR]: <CreateLendingAccount />,
      [LEAD_ASSESSOR]: <CreateLendingAccount />,
      [BANKING_ADMIN]: <CreateLendingAccount />,
      [BANKING_CLIENT]: <CreateBankingClientAccount />,
      [BANKING_PORTFOLIO_ADMIN]: <CreateBankingClientAccount />,
    }[role])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const invitationCode = urlParams.get('code')

    dispatch(getInvitation(invitationCode))
  }, [dispatch])

  switch (status) {
    case PENDING:
      return <Loading />
    case REJECTED:
      return <ErrorPage />
    default:
      return <>{getCreateAccountPage(role)}</>
  }
}

export { CreateAccount }
